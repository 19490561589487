import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, MessageCircle, Send, RefreshCw, Square } from 'lucide-react';
import { useAppSelector } from "../../hooks/reduxHooks";
import { Avatar } from '../ShadcnUi/avatar';
import { Button } from '../ShadcnUi/button';
import { Input } from '../ShadcnUi/input';
import { useChatWebSocket } from './useChatWebSocket';
import SensoLogo from '../../images/senso-logo-small.png';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

// Add custom scrollbar styles
const scrollbarStyles = `
  /* Width */
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.2);
  }
`;

// Dummy FAQs data - replace with your actual questions
const FAQS = [
  "How do I get started with the senso platform?",
  "What are the key features?",
  "How do I reset my password?",
  "How do I upload my documents?",
  "How do I create a collection?"
];

const ChatBotWidget: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const wasOpenRef = useRef(false); // Track previous open state

  const currentUser = useAppSelector((state) => state.user.currentUser);
  const userName = currentUser?.user?.given_name || 'there';

  // Always call the hook
  const { messages, sendMessage, isTyping, isConnected, isStreaming, reconnect, initialize, disconnect, cancelStream } = useChatWebSocket();

  // Initialize or close the connection when isOpen changes
  useEffect(() => {
    // Only initialize if the widget transitioned from closed to open
    if (isOpen && !wasOpenRef.current) {
      console.log("Widget opened, initializing connection");
      initialize();
    }
    // Only disconnect if the widget transitioned from open to closed
    else if (!isOpen && wasOpenRef.current) {
      console.log("Widget closed, disconnecting");
      disconnect();
    }

    // Update reference for next check
    wasOpenRef.current = isOpen;
  }, [isOpen, initialize, disconnect]);

  const toggleChat = () => {
    setIsOpen(!isOpen);
    // Focus input when chat opens
    if (!isOpen) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 300);
    }
  };

  const handleSendMessage = () => {
    if (inputValue.trim()) {
      sendMessage(inputValue);
      setInputValue('');
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleFAQClick = (question: string) => {
    sendMessage(question);
  };

  const handleNewSession = () => {
    disconnect();
    setTimeout(() => {
      initialize();
    }, 300);
  };

  // Scroll to bottom when messages change
  useEffect(() => {
    if (isOpen) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, isOpen]);

  return (
    <>
      {/* Add the scrollbar styles */}
      <style>{scrollbarStyles}</style>

      <div className="fixed bottom-4 right-4 z-50">
        <AnimatePresence>
          {isOpen ? (
            <motion.div
              initial={{ opacity: 0, y: 20, scale: 0.9 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, y: 20, scale: 0.9 }}
              transition={{ duration: 0.3 }}
              className="bg-white rounded-lg shadow-xl w-[400px] sm:w-[520px] overflow-hidden flex flex-col"
              style={{ height: "650px" }}
            >
              {/* Updated Header */}
              <div className="bg-white p-4 border-b flex items-center justify-between">
                <div className="flex items-center gap-2">
                  {/* Senso Logo */}
                  <img
                    src={SensoLogo}
                    alt="Senso"
                    className="h-8 w-auto"
                  />
                  <h3 className="font-semibold text-black">Senso Support</h3>
                </div>
                <div className="flex items-center gap-2">
                  {/* New Session Button - only show after first message */}
                  {messages.length > 0 && (
                    <motion.div
                      whileHover={{ scale: 1.1 }}
                      transition={{ duration: 0.2 }}
                    >
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={handleNewSession}
                        className="text-black hover:bg-gray-100 hover:text-senso-green h-8 w-8"
                        title="Start a new conversation"
                      >
                        <RefreshCw size={18} />
                      </Button>
                    </motion.div>
                  )}

                  {/* Close Button with Animation */}
                  <motion.div
                    whileHover={{ scale: 1.1 }}
                    transition={{ duration: 0.2 }}
                  >
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={toggleChat}
                      className="text-black hover:bg-gray-100 hover:text-senso-green h-8 w-8"
                    >
                      <X size={18} />
                    </Button>
                  </motion.div>
                </div>
              </div>

              {/* Chat Content with modified scrollbar */}
              <div className="flex-1 overflow-y-auto p-4 flex flex-col gap-4 custom-scrollbar relative">
                {/* Enhanced Connection Status Message - positioned absolutely */}
                {!isConnected && (
                  <div className="absolute inset-0 bg-white bg-opacity-80 flex items-center justify-center z-10">
                    <motion.div
                      animate={{
                        opacity: [0.6, 1, 0.6],
                        scale: [0.98, 1.02, 0.98]
                      }}
                      transition={{
                        duration: 1.5,
                        repeat: Infinity,
                        ease: "easeInOut"
                      }}
                      className="font-bold text-xl text-senso-green"
                    >
                      Connecting
                      <motion.span
                        animate={{ opacity: [0, 1, 0] }}
                        transition={{ duration: 1.2, repeat: Infinity }}
                      >.</motion.span>
                      <motion.span
                        animate={{ opacity: [0, 1, 0] }}
                        transition={{ duration: 1.2, repeat: Infinity, delay: 0.2 }}
                      >.</motion.span>
                      <motion.span
                        animate={{ opacity: [0, 1, 0] }}
                        transition={{ duration: 1.2, repeat: Infinity, delay: 0.4 }}
                      >.</motion.span>
                    </motion.div>
                  </div>
                )}

                {/* Greeting - show only if no messages yet */}
                {messages.length === 0 && (
                  <>
                    {/* Two-part greeting with larger greeting text */}
                    <div className="mb-2">
                      <div className="text-xl font-semibold">
                        Hi {userName} 👋
                      </div>
                      <div className="text-base text-slate-700 mt-2">
                        I'm here to help answer your questions about the Senso Platform. How can I assist you today?
                      </div>
                    </div>
                    
                    {/* Updated FAQs Section */}
                    <div className="rounded-lg border overflow-hidden shadow-sm mt-4">
                      {/* Header with light gray background */}
                      <div className="bg-gray-100 px-4 py-3 text-sm font-semibold text-black">
                        Frequently asked questions
                      </div>

                      {/* Questions container - remove the divide-y to eliminate lines */}
                      <div className="p-2">
                        {FAQS.map((question, index) => (
                          <button
                            key={index}
                            className="w-full text-left px-4 py-3 my-1 text-sm text-black bg-white hover:bg-gray-50 transition-colors rounded"
                            onClick={() => handleFAQClick(question)}
                          >
                            {question}
                          </button>
                        ))}
                      </div>
                    </div>
                  </>
                )}

                {/* Messages with improved styling and conditional ReactMarkdown */}
                {messages.map((message, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.2 }}
                    className={`p-3 rounded-lg ${
                      message.isUser 
                        ? 'bg-senso-green/90 text-white ml-auto shadow-sm rounded-br-none' 
                        : 'bg-gray-100 text-slate-700 mr-auto shadow-sm rounded-bl-none'
                    } max-w-[85%]`}
                  >
                    {message.isUser ? (
                      <div className="text-sm">{message.content}</div>
                    ) : (
                      <ReactMarkdown className='markdown text-sm' remarkPlugins={[remarkGfm]}>
                        {message.content}
                      </ReactMarkdown>
                    )}
                  </motion.div>
                ))}

                {/* Typing indicator - show when AI is typing and after the user's message */}
                {isTyping && (
                  <div className="bg-gray-100 p-3 rounded-lg mr-auto max-w-[80%] shadow-sm rounded-bl-none">
                    <div className="flex space-x-1">
                      <div className="h-2 w-2 rounded-full bg-gray-400 animate-bounce"></div>
                      <div className="h-2 w-2 rounded-full bg-gray-400 animate-bounce delay-150"></div>
                      <div className="h-2 w-2 rounded-full bg-gray-400 animate-bounce delay-300"></div>
                    </div>
                  </div>
                )}

                <div ref={messagesEndRef} />
              </div>

              {/* Input Area with disabled state during responses */}
              <div className="p-4 border-t">
                <div className="flex gap-2">
                  <Input
                    ref={inputRef}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyPress}
                    placeholder="Type your question..."
                    className="flex-1"
                    disabled={!isConnected || isTyping || isStreaming}
                  />
                  {isTyping || isStreaming ? (
                    <motion.div
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <Button
                        size="icon"
                        className="bg-red-500 hover:bg-red-600"
                        onClick={() => cancelStream()}
                        title="Stop generating"
                      >
                        <Square size={16} fill="white" />
                      </Button>
                    </motion.div>
                  ) : (
                    <motion.div
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <Button
                        size="icon"
                        className="bg-senso-green hover:bg-senso-green/90"
                        onClick={handleSendMessage}
                        disabled={!isConnected || !inputValue.trim()}
                      >
                        <Send size={18} />
                      </Button>
                    </motion.div>
                  )}
                </div>
              </div>
            </motion.div>
          ) : (
            <motion.button
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={toggleChat}
              className="bg-senso-green text-white rounded-full w-14 h-14 flex items-center justify-center shadow-lg hover:shadow-xl transition-all"
            >
              <MessageCircle size={26} />
            </motion.button>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

export default ChatBotWidget; 