import { useState, useEffect, useCallback, useRef } from 'react';
import { useAppSelector } from '../../hooks/reduxHooks';
import { v4 as uuidv4 } from 'uuid';
import { API_URL } from '../../config';

interface Message {
  type: string;
  content: string;
  isUser: boolean;
  timestamp: string;
}

interface WebSocketHookReturn {
  messages: Message[];
  sendMessage: (content: string) => void;
  isTyping: boolean;
  isConnected: boolean;
  isStreaming: boolean;
  reconnect: () => void;
  initialize: () => void;
  disconnect: () => void;
  cancelStream: () => void;
}

const HEARTBEAT_INTERVAL = 25000; // 25 seconds

export const useChatWebSocket = (): WebSocketHookReturn => {
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [connectionId, setConnectionId] = useState<string | null>(null);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [isStreaming, setIsStreaming] = useState<boolean>(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const heartbeatRef = useRef<NodeJS.Timeout | null>(null);
  const lastMessageIdRef = useRef<string | null>(null);
  const roomIdRef = useRef<string>(uuidv4());
  
  const currentUser = useAppSelector((state) => state.user.currentUser);
  
  const handleMessage = useCallback((data: any, ws: WebSocket) => {
    const msgType = data.type;
    const payload = data.payload || {};
    
    switch (msgType) {
      case 'session_start':
        setSessionId(payload.session_id);
        setConnectionId(payload.connection_id);
        break;
        
      case 'typing_indicator':
        setIsTyping(payload.status === 'started');
        break;
        
      case 'assistant_message_chunk':
        if (payload.chunk) {
          setIsStreaming(true);
          
          // Update latest assistant message or create a new one
          setMessages(prevMessages => {
            const lastMessage = prevMessages[prevMessages.length - 1];
            
            if (lastMessage && !lastMessage.isUser) {
              // Update the last assistant message
              const updatedMessages = [...prevMessages];
              updatedMessages[updatedMessages.length - 1] = {
                ...lastMessage,
                content: lastMessage.content + payload.chunk
              };
              return updatedMessages;
            } else {
              // Create a new assistant message
              return [...prevMessages, {
                type: 'assistant_message',
                content: payload.chunk,
                isUser: false,
                timestamp: new Date().toISOString()
              }];
            }
          });
        }
        
        // Check if this is the final chunk
        if (payload.is_final) {
          setIsStreaming(false);
        }
        break;
        
      case 'assistant_message':
        setIsStreaming(false);
        break;
        
      case 'ping':
        // Respond with pong immediately when receiving a ping
        console.log('Received ping, sending pong...');
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify({
            type: 'pong',
            payload: {
              session_id: payload.session_id,
              timestamp: new Date().toISOString()
            }
          }));
        }
        break;
        
      case 'error':
        console.error(`WebSocket error (${payload.error_code}): ${payload.error_message}`);
        break;
        
      default:
        console.log('Received message of type:', msgType);
        break;
    }
  }, []);
  
  const startHeartbeat = useCallback((ws: WebSocket) => {
    if (heartbeatRef.current) {
      clearInterval(heartbeatRef.current);
    }
    
    heartbeatRef.current = setInterval(() => {
      if (ws.readyState === WebSocket.OPEN && sessionId) {
        console.log('Sending client ping...');
        ws.send(JSON.stringify({
          type: 'ping',
          payload: {
            session_id: sessionId,
            timestamp: new Date().toISOString()
          }
        }));
      }
    }, HEARTBEAT_INTERVAL);
  }, [sessionId]);
  
  // No automatic initialization - we'll initialize on demand
  const connectWebSocket = useCallback(() => {
    // Close existing connection if any
    if (socket) {
      socket.close();
      if (heartbeatRef.current) {
        clearInterval(heartbeatRef.current);
        heartbeatRef.current = null;
      }
    }
    
    // For testing, log connection attempt
    console.log('Connecting to WebSocket...');
    
    const roomId = roomIdRef.current;
    const WS_URL = API_URL!.replace("http", "ws");
    const ws = new WebSocket(`${WS_URL}/ws/support/${roomId}/`);  //(`wss://beta-api.senso.ai/ws/support/${roomId}/`);
    
    ws.onopen = () => {
      console.log('WebSocket connected!');
      setIsConnected(true);
      // Send session_start message
      const clientId = crypto.randomUUID();
      
      const sessionStartMsg = {
        type: "session_start",
        payload: {
          client_generated_id: clientId,
          source: "user_input",
          user_context: {
            user_id: currentUser?.user?.user_id || "anonymous",
            email: currentUser?.user?.email || "",
            name: currentUser?.user?.given_name || "Anonymous User",
            organization_id: currentUser?.org_id || "",
            organization_name: currentUser?.org_name || ""
          },
          current_page: {
            url: window.location.href,
            path: window.location.pathname,
            title: document.title
          },
          timestamp: new Date().toISOString()
        }
      };
      
      ws.send(JSON.stringify(sessionStartMsg));
      console.log('Session start message sent');
      startHeartbeat(ws);
    };
    
    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        console.log('Received message:', data.type);
        handleMessage(data, ws);
      } catch (error) {
        console.error('Error parsing message:', error);
      }
    };
    
    ws.onclose = (event) => {
      console.log(`WebSocket closed with code: ${event.code}, reason: ${event.reason}`);
      setIsConnected(false);
      if (heartbeatRef.current) {
        clearInterval(heartbeatRef.current);
        heartbeatRef.current = null;
      }
    };
    
    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
      setIsConnected(false);
    };
    
    setSocket(ws);
  }, [currentUser, handleMessage, startHeartbeat]);
  
  // Cleanup function
  useEffect(() => {
    return () => {
      console.log('Cleaning up WebSocket connection...');
      if (socket) {
        socket.close();
      }
      if (heartbeatRef.current) {
        clearInterval(heartbeatRef.current);
        heartbeatRef.current = null;
      }
    };
  }, [socket]);
  
  const sendMessage = useCallback((content: string) => {
    if (socket?.readyState === WebSocket.OPEN && sessionId) {
      const messageId = crypto.randomUUID();
      lastMessageIdRef.current = messageId;
      
      const message = {
        type: 'user_message',
        payload: {
          session_id: sessionId,
          message_id: messageId,
          content,
          user_context: {
            user_id: currentUser?.user?.user_id || "anonymous",
            email: currentUser?.user?.email || "",
            name: currentUser?.user?.given_name || "Anonymous User",
            organization_id: currentUser?.org_id || "",
            organization_name: currentUser?.org_name || ""
          },
          current_page: {
            url: window.location.href,
            path: window.location.pathname,
            title: document.title
          },
          timestamp: new Date().toISOString()
        }
      };
      
      socket.send(JSON.stringify(message));
      console.log('User message sent:', content);
      
      // Add message to local state
      setMessages(prev => [...prev, {
        type: 'user_message',
        content,
        isUser: true,
        timestamp: new Date().toISOString()
      }]);
    } else {
      console.warn('Cannot send message: WebSocket not connected or no session ID');
    }
  }, [socket, sessionId, currentUser]);
  
  const reconnect = useCallback(() => {
    console.log('Manually reconnecting...');
    if (socket) {
      socket.close();
    }
    connectWebSocket();
  }, [socket, connectWebSocket]);
  
  // Add disconnect method
  const disconnect = useCallback(() => {
    console.log('Disconnecting WebSocket...');
    if (socket) {
      socket.close();
      setSocket(null);
    }
    if (heartbeatRef.current) {
      clearInterval(heartbeatRef.current);
      heartbeatRef.current = null;
    }
    setIsConnected(false);
  }, [socket]);
  
  // Use initialization flag to avoid multiple initializations
  const hasInitializedRef = useRef(false);
  
  const initialize = useCallback(() => {
    // Only initialize if not already initialized
    if (hasInitializedRef.current) {
      console.log('WebSocket already initialized, skipping');
      return;
    }
    
    console.log('Initializing WebSocket...');
    hasInitializedRef.current = true;
    
    // Reset state for a new connection
    setMessages([]);
    setIsTyping(false);
    connectWebSocket();
  }, [connectWebSocket]);
  
  // Update cleanup function to reset the initialization flag
  useEffect(() => {
    return () => {
      console.log('Cleaning up WebSocket connection...');
      if (socket) {
        socket.close();
      }
      if (heartbeatRef.current) {
        clearInterval(heartbeatRef.current);
        heartbeatRef.current = null;
      }
      // Reset initialization flag on cleanup
      hasInitializedRef.current = false;
    };
  }, [socket]);
  
  const cancelStream = useCallback(() => {
    if (socket?.readyState === WebSocket.OPEN && sessionId) {
      console.log('Sending cancel message');
      const message = {
        type: 'cancel',
        payload: {
          session_id: sessionId,
          timestamp: new Date().toISOString()
        }
      };
      
      socket.send(JSON.stringify(message));
    } else {
      console.warn('Cannot cancel: WebSocket not connected or no session ID');
    }
  }, [socket, sessionId]);
  
  return {
    messages,
    sendMessage,
    isTyping,
    isConnected,
    isStreaming,
    reconnect,
    initialize,
    disconnect,
    cancelStream
  };
}; 